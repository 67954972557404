/**
 * Do not edit directly
 * Generated on Fri, 18 Aug 2023 08:01:08 GMT
 */

.OneDarkTheme {
  --gdevelop-color-light-blue: #4ab0e4;
  --gdevelop-color-blue: #2c6bf5;
  --gdevelop-color-dark-blue: #3c4698;
  --gdevelop-color-purple: #9100ce;
  --gdevelop-color-contrast-text: #fff;
  --gdevelop-color-selection: #4c92ff; /* OS X selection */
  --gdevelop-classic-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, 'Helvetica Neue', Helvetica, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --gdevelop-modern-font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, 'Helvetica Neue', Helvetica, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --theme-primary-color: #61AFEF;
  --theme-primary-text-contrast-color: #FFFFFF;
  --theme-secondary-color: #56B6C2;
  --theme-secondary-text-contrast-color: #282C34;
  --theme-surface-titlebar-background-color: #1D1D26; /* Palette/Grey/100 */
  --theme-surface-toolbar-background-color: #25252E; /* Palette/Grey/90 */
  --theme-surface-window-background-color: #21252B;
  --theme-surface-canvas-events-highlighted-background-color: #5b4c31;
  --theme-surface-canvas-events-highlighted-color: #E5C07B;
  --theme-surface-canvas-background-color: #282C34;
  --theme-surface-alternate-canvas-background-color: #21252B;
  --theme-surface-alternate-canvas-light-background-color: #272C31;
  --theme-dialog-background-color: #21252B;
  --theme-dialog-separator-color: #494952; /* Palette/Grey/70 */
  --theme-swipeable-drawer-top-bar-pill-color: #14161a;
  --theme-icon-button-selected-color: #25252E; /* Palette/Grey/90 */
  --theme-icon-button-selected-background-color: #56B6C2;
  --theme-link-color: #81CFFF;
  --theme-link-hover-color: #61AFEF;
  --theme-switch-default-thumb-color: #EBEBED; /* Palette/Grey/10 */
  --theme-switch-default-track-color: #606066; /* Palette/Grey/60 */
  --theme-switch-toggled-thumb-color: #81CFFF;
  --theme-switch-toggled-track-color: #61AFEF;
  --theme-switch-disabled-thumb-color: #494952; /* Palette/Grey/70 */
  --theme-switch-disabled-track-color: #32323B; /* Palette/Grey/80 */
  --theme-search-bar-default-background-color: #494952; /* Palette/Grey/70 */
  --theme-search-bar-default-text-color: #C5C5C9; /* Palette/Grey/30 */
  --theme-search-bar-hovered-border-color: #7F7F85; /* Palette/Grey/50 */
  --theme-search-bar-focused-text-color: #FAFAFA; /* Palette/Grey/00 */
  --theme-search-bar-focused-border-color: #F6F2FF; /* Palette/Purple/00 */
  --theme-search-bar-disabled-background-color: #32323B; /* Palette/Grey/80 */
  --theme-search-bar-disabled-text-color: #494952; /* Palette/Grey/70 */
  --theme-selection-background-color: #3E4452;
  --theme-selection-color: #D6DEEC;
  --theme-text-default-color: #D6DEEC;
  --theme-text-secondary-color: #ABB2BF;
  --theme-text-disabled-color: #9AA1AD;
  --theme-text-contrast-color: #D6DEEC;
  --theme-text-highlighted-background-color: rgb(214, 222, 236, 0.3);
  --theme-message-warning-color: #E5C07B;
  --theme-message-error-color: #E06C75;
  --theme-message-valid-color: #98C379;
  --theme-message-empty-shadow-color: transparent;
  --theme-toolbar-separator-color: #3E4452;
  --theme-closable-tabs-default-background-color: #32323B; /* Palette/Grey/80 */
  --theme-closable-tabs-default-color: #C5C5C9; /* Palette/Grey/30 */
  --theme-closable-tabs-selected-background-color: #25252E; /* Palette/Grey/90 */
  --theme-closable-tabs-selected-color: #FAFAFA; /* Palette/Grey/00 */
  --theme-closable-tabs-selected-border-color: #61AFEF;
  --theme-list-item-group-text-color: #ABB2BF;
  --theme-list-item-group-text-deprecated-color: #9AA1AD;
  --theme-list-item-separator-color: #3E4452;
  --theme-list-item-warning-color: #E5C07B;
  --theme-list-item-error-color: #E06C75;
  --theme-right-icon-color: #FFFFFF;
  --theme-palette-black: #6E2A2A;
  --theme-palette-white: #D6DEEC;
  --theme-image-preview-background-filter: invert(100%) invert(19%) sepia(26%) saturate(365%) hue-rotate(168deg) brightness(55%) contrast(92%);
  --theme-image-preview-border-color: #3B3F46;
  --theme-image-preview-frame-border-color: #D6DEEC;
  --theme-drop-indicator-can-drop-color: #D6DEEC;
  --theme-drop-indicator-cannot-drop-color: #E06C75;
  --theme-drop-indicator-border-color: #21252B;
  --theme-home-separator-color: #32323B;
  --theme-linear-progress-color-complete: #16CF89; /* Palette/Green/50 */
  --theme-linear-progress-color-incomplete: #FFA929; /* Palette/Yellow/50 */
  --theme-example-difficulty-color-simple: #8BE7C4;
  --theme-example-difficulty-color-advanced: #FFD28F;
  --theme-example-difficulty-color-expert: #FFA38F;
  --theme-tabs-text-color: #7F7F85; /* Palette/Grey/50 */
  --theme-tabs-background-color: #272C31;
  --theme-tabs-indicator-color: #A6A6AB; /* Palette/Grey/40 */
  --theme-tabs-separator-color: #606066; /* Palette/Grey/60 */
  --theme-tabs-selected-text-color: #FAFAFA; /* Palette/Grey/00 */
  --theme-tabs-selected-background-color: #494952; /* Palette/Grey/70 */
  --input-border-bottom-color: #D6DEEC;
  --event-sheet-event-tree-background-color: #282C34;
  --event-sheet-rst-move-handle-background-color: #3E4452;
  --event-sheet-rst-move-handle-hover-background-color: #6C7D96;
  --event-sheet-rst-line-background-color: #ABB2BF;
  --event-sheet-rst-row-contents-background-color: #282C34;
  --event-sheet-rst-row-contents-color: #D6DEEC;
  --event-sheet-selectable-background-color: rgba(0, 0, 0, 0.1);
  --event-sheet-selectable-border: 1px #282C34 solid;
  --event-sheet-selectable-selected-border: 1px #4AB0E4 dashed;
  --event-sheet-conditions-background-color: #3E4452;
  --event-sheet-conditions-border-color: #3E4452;
  --event-sheet-conditions-border: 1px #3E4452 solid;
  --event-sheet-conditions-color: rgb(209, 209, 209);
  --event-sheet-conditions-border-right-color: #494949;
  --event-sheet-conditions-border-bottom-color: black;
  --event-sheet-events-highlighted-background-color: #3d4d51;
  --event-sheet-events-highlighted-color: #E5C07B;
  --event-sheet-actions-background-color: #282C34;
  --event-sheet-actions-color: #D6DEEC;
  --event-sheet-sub-instructions-border-color: #282C34;
  --event-sheet-sub-instructions-border: 1px #282C34 solid;
  --event-sheet-instruction-parameter-base-color: #98C379;
  --event-sheet-instruction-parameter-number-color: #E5C07B;
  --event-sheet-instruction-parameter-object-color: #C678DD;
  --event-sheet-instruction-parameter-behavior-color: #E09563;
  --event-sheet-instruction-parameter-operator-color: #B77CFF;
  --event-sheet-instruction-parameter-var-color: #56B6C2;
  --event-sheet-instruction-parameter-error-color: #E06C75;
  --event-sheet-instruction-parameter-error-background-color: #E06C7544;
  --event-sheet-instruction-parameter-warning-color: #E5C07B;
  --event-sheet-drop-indicator-can-drop-border-top-color: #61AFEF;
  --event-sheet-drop-indicator-cannot-drop-border-top-color: #E06C75;
  --event-sheet-link-container-background-color: #3E4452;
  --event-sheet-link-container-color: #D6DEEC;
  --event-sheet-link-selectable-link-color: #C678DD;
  --markdown-blockquote-border-left-color: rgba(221, 221, 221, 0.6);
  --markdown-link-color: rgb(221, 221, 221);
  --mosaic-title-color: #FFFFFF;
  --mosaic-layout-border-color: #3E4452;
  --mosaic-layout-background-color: #25252E; /* Palette/Grey/90 */
  --mosaic-toolbar-background-color: #4C5361;
  --mosaic-toolbar-color: #D6DEEC;
  --mosaic-toolbar-border-color-hover: #D6DEEC;
  --table-border-color: #282C34;
  --table-text-color: #ABB2BF;
  --table-text-color-header: #D6DEEC;
  --table-row-odd-background-color: #282C34;
  --table-row-even-background-color: #21252B;
  --table-row-selected-background-color: #3E4452;
  --table-row-selected-color: #D6DEEC;
}
