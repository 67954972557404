/**
 * Do not edit directly
 * Generated on Fri, 18 Aug 2023 08:01:08 GMT
 */

.SolarizedDarkTheme {
  --gdevelop-color-light-blue: #4ab0e4;
  --gdevelop-color-blue: #2c6bf5;
  --gdevelop-color-dark-blue: #3c4698;
  --gdevelop-color-purple: #9100ce;
  --gdevelop-color-contrast-text: #fff;
  --gdevelop-color-selection: #4c92ff; /* OS X selection */
  --gdevelop-classic-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, 'Helvetica Neue', Helvetica, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --gdevelop-modern-font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, 'Helvetica Neue', Helvetica, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --theme-primary-color: #047AA6;
  --theme-primary-text-contrast-color: #fff;
  --theme-secondary-color: #2799b9;
  --theme-secondary-text-contrast-color: #fff;
  --theme-surface-titlebar-background-color: #1D1D26; /* Palette/Grey/100 */
  --theme-surface-toolbar-background-color: #002B36;
  --theme-surface-window-background-color: #00212B;
  --theme-surface-canvas-background-color: #002B36;
  --theme-surface-alternate-canvas-background-color: #00212B;
  --theme-surface-alternate-canvas-light-background-color: #002731;
  --theme-dialog-background-color: #00212B;
  --theme-dialog-separator-color: #494952; /* Palette/Grey/70 */
  --theme-swipeable-drawer-top-bar-pill-color: #001419;
  --theme-icon-button-color: #002B36;
  --theme-icon-button-selected-background-color: #2799b9;
  --theme-link-color: #249AC6;
  --theme-link-hover-color: #047AA6;
  --theme-switch-default-thumb-color: #EBEBED; /* Palette/Grey/10 */
  --theme-switch-default-track-color: #606066; /* Palette/Grey/60 */
  --theme-switch-toggled-thumb-color: #249AC6;
  --theme-switch-toggled-track-color: #047AA6;
  --theme-switch-disabled-thumb-color: #494952; /* Palette/Grey/70 */
  --theme-switch-disabled-track-color: #32323B; /* Palette/Grey/80 */
  --theme-search-bar-default-background-color: #494952; /* Palette/Grey/70 */
  --theme-search-bar-default-text-color: #C5C5C9; /* Palette/Grey/30 */
  --theme-search-bar-hovered-border-color: #7F7F85; /* Palette/Grey/50 */
  --theme-search-bar-focused-text-color: #FAFAFA; /* Palette/Grey/00 */
  --theme-search-bar-focused-border-color: #F6F2FF; /* Palette/Purple/00 */
  --theme-search-bar-disabled-background-color: #32323B; /* Palette/Grey/80 */
  --theme-search-bar-disabled-text-color: #494952; /* Palette/Grey/70 */
  --theme-selection-background-color: #047AA6;
  --theme-selection-color: #FFFFFF;
  --theme-text-default-color: #DDD;
  --theme-text-secondary-color: #CCC;
  --theme-text-disabled-color: #888888;
  --theme-text-contrast-color: #fff;
  --theme-text-highlighted-background-color: rgb(255, 255, 255, 0.3);
  --theme-message-warning-color: #ffa500;
  --theme-message-error-color: #f00;
  --theme-message-valid-color: #00db00;
  --theme-message-empty-shadow-color: #000;
  --theme-toolbar-separator-color: #003847;
  --theme-closable-tabs-default-background-color: #32323B; /* Palette/Grey/80 */
  --theme-closable-tabs-default-color: #C5C5C9; /* Palette/Grey/30 */
  --theme-closable-tabs-selected-background-color: #002B36;
  --theme-closable-tabs-selected-color: #FAFAFA; /* Palette/Grey/00 */
  --theme-closable-tabs-selected-border-color: #047AA6;
  --theme-list-item-group-text-color: #AAA;
  --theme-list-item-group-text-deprecated-color: #888;
  --theme-list-item-separator-color: #003847;
  --theme-list-item-warning-color: #ffb032;
  --theme-list-item-error-color: #ff2e16;
  --theme-right-icon-color: #fff;
  --theme-palette-black: rgba(110, 42, 42, 1);
  --theme-palette-white: #fff;
  --theme-image-preview-background-filter: invert(100%) invert(13%) sepia(94%) saturate(777%) hue-rotate(154deg) brightness(49%) contrast(90%);
  --theme-image-preview-border-color: #173e48;
  --theme-image-preview-frame-border-color: #dcdcdc;
  --theme-drop-indicator-can-drop-color: #DCDCDC;
  --theme-drop-indicator-cannot-drop-color: #BF616A;
  --theme-drop-indicator-border-color: #002B36;
  --theme-home-separator-color: #32323B;
  --theme-linear-progress-color-complete: #16CF89; /* Palette/Green/50 */
  --theme-linear-progress-color-incomplete: #FFA929; /* Palette/Yellow/50 */
  --theme-example-difficulty-color-simple: #8BE7C4;
  --theme-example-difficulty-color-advanced: #FFD28F;
  --theme-example-difficulty-color-expert: #FFA38F;
  --theme-tabs-text-color: #7F7F85; /* Palette/Grey/50 */
  --theme-tabs-background-color: #002731;
  --theme-tabs-indicator-color: #A6A6AB; /* Palette/Grey/40 */
  --theme-tabs-separator-color: #606066; /* Palette/Grey/60 */
  --theme-tabs-selected-text-color: #FAFAFA; /* Palette/Grey/00 */
  --theme-tabs-selected-background-color: #494952; /* Palette/Grey/70 */
  --input-border-bottom-color: #444444;
  --event-sheet-event-tree-background-color: #00212B;
  --event-sheet-rst-move-handle-background-color: #2799b9;
  --event-sheet-rst-move-handle-hover-background-color: rgb(31, 116, 150);
  --event-sheet-rst-line-background-color: #D8DEE9;
  --event-sheet-rst-row-contents-background-color: #00212B;
  --event-sheet-rst-row-contents-color: rgb(209, 209, 209);
  --event-sheet-selectable-background-color: rgba(0, 0, 0, 0.2);
  --event-sheet-selectable-border: 1px #00212B solid;
  --event-sheet-selectable-selected-border: 1px #00aaff dashed;
  --event-sheet-conditions-background-color: #002B36;
  --event-sheet-conditions-border-color: #002B36;
  --event-sheet-conditions-border: 1px solid #002B36;
  --event-sheet-conditions-color: rgb(209, 209, 209);
  --event-sheet-conditions-border-right-color: #00212B;
  --event-sheet-conditions-border-bottom-color: #002B36;
  --event-sheet-events-highlighted-background-color: #3d4d51;
  --event-sheet-events-highlighted-color: #859900;
  --event-sheet-actions-background-color: #00212B;
  --event-sheet-actions-color: rgb(209, 209, 209);
  --event-sheet-sub-instructions-border-color: #00212B;
  --event-sheet-sub-instructions-border: 1px solid #00212B;
  --event-sheet-instruction-parameter-base-color: #219186;
  --event-sheet-instruction-parameter-number-color: #859900;
  --event-sheet-instruction-parameter-object-color: #268BD2;
  --event-sheet-instruction-parameter-behavior-color: #586e75;
  --event-sheet-instruction-parameter-operator-color: #D33682;
  --event-sheet-instruction-parameter-var-color: #cb4b16;
  --event-sheet-instruction-parameter-error-color: #dc322f;
  --event-sheet-instruction-parameter-error-background-color: rgba(238, 255, 0, 0.05);
  --event-sheet-instruction-parameter-warning-color: #ffa500;
  --event-sheet-drop-indicator-can-drop-border-top-color: #18dcf2;
  --event-sheet-drop-indicator-cannot-drop-border-top-color: #BF616A;
  --event-sheet-link-container-background-color: #00212B;
  --event-sheet-link-container-color: rgb(155, 155, 155);
  --event-sheet-link-selectable-link-color: #268BD2;
  --markdown-blockquote-border-left-color: #D8DEE9;
  --markdown-link-color: #ECEFF4;
  --mosaic-title-color: #ECEFF4;
  --mosaic-layout-border-color: rgb(92, 118, 126);
  --mosaic-layout-background-color: #002B36;
  --mosaic-toolbar-background-color: #2799b9;
  --mosaic-toolbar-border-color-hover: rgb(92, 118, 126);
  --table-border-color: #002B36;
  --table-text-color: #ECEFF4;
  --table-text-color-header: rgb(216, 233, 217);
  --table-row-odd-background-color: #002B36;
  --table-row-even-background-color: #00212B;
  --table-row-selected-background-color: #047AA6;
  --table-row-selected-color: #002B36;
}
