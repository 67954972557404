/**
 * Do not edit directly
 * Generated on Fri, 18 Aug 2023 08:01:08 GMT
 */

.RosePineTheme {
  --gdevelop-color-light-blue: #4ab0e4;
  --gdevelop-color-blue: #2c6bf5;
  --gdevelop-color-dark-blue: #3c4698;
  --gdevelop-color-purple: #9100ce;
  --gdevelop-color-contrast-text: #fff;
  --gdevelop-color-selection: #4c92ff; /* OS X selection */
  --gdevelop-classic-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, 'Helvetica Neue', Helvetica, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --gdevelop-modern-font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, 'Helvetica Neue', Helvetica, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --theme-primary-color: #c4a7e7;
  --theme-primary-text-contrast-color: #191724;
  --theme-secondary-color: #ebbcba;
  --theme-secondary-text-contrast-color: #191724;
  --theme-surface-titlebar-background-color: #191724;
  --theme-surface-toolbar-background-color: #1f1d2e;
  --theme-surface-window-background-color: #191724;
  --theme-surface-canvas-background-color: #1f1d2e;
  --theme-surface-alternate-canvas-background-color: #191724;
  --theme-surface-alternate-canvas-light-background-color: #1F1D2A;
  --theme-dialog-background-color: #191724;
  --theme-dialog-separator-color: #494952; /* Palette/Grey/70 */
  --theme-swipeable-drawer-top-bar-pill-color: #907aa9;
  --theme-icon-button-color: #1f1d2e;
  --theme-icon-button-selected-background-color: #ebbcba;
  --theme-link-color: #E4C7FF;
  --theme-link-hover-color: #C4A7E7;
  --theme-switch-default-thumb-color: #EBEBED; /* Palette/Grey/10 */
  --theme-switch-default-track-color: #606066; /* Palette/Grey/60 */
  --theme-switch-toggled-thumb-color: #E4C7FF;
  --theme-switch-toggled-track-color: #C4A7E7;
  --theme-switch-disabled-thumb-color: #494952; /* Palette/Grey/70 */
  --theme-switch-disabled-track-color: #32323B; /* Palette/Grey/80 */
  --theme-search-bar-default-background-color: #494952; /* Palette/Grey/70 */
  --theme-search-bar-default-text-color: #C5C5C9; /* Palette/Grey/30 */
  --theme-search-bar-hovered-border-color: #7F7F85; /* Palette/Grey/50 */
  --theme-search-bar-focused-text-color: #FAFAFA; /* Palette/Grey/00 */
  --theme-search-bar-focused-border-color: #F6F2FF; /* Palette/Purple/00 */
  --theme-search-bar-disabled-background-color: #32323B; /* Palette/Grey/80 */
  --theme-search-bar-disabled-text-color: #494952; /* Palette/Grey/70 */
  --theme-selection-background-color: #c4a7e7;
  --theme-selection-color: #e0def4;
  --theme-text-default-color: #e0def4;
  --theme-text-secondary-color: #908caa;
  --theme-text-disabled-color: #6e6a86;
  --theme-text-contrast-color: #908caa;
  --theme-text-highlighted-background-color: rgba(255, 255, 255, 0.3);
  --theme-message-warning-color: #f6c177;
  --theme-message-error-color: #eb6f92;
  --theme-message-valid-color: #31748f;
  --theme-message-empty-shadow-color: #191724;
  --theme-toolbar-separator-color: #6e6a86;
  --theme-closable-tabs-default-background-color: #6e6a86; /* Palette/Grey/80 */
  --theme-closable-tabs-default-color: #C5C5C9; /* Palette/Grey/30 */
  --theme-closable-tabs-selected-background-color: #1f1d2e;
  --theme-closable-tabs-selected-color: #FAFAFA; /* Palette/Grey/00 */
  --theme-closable-tabs-selected-border-color: #c4a7e7;
  --theme-list-item-group-text-color: #e0def4;
  --theme-list-item-group-text-deprecated-color: #6e6a86;
  --theme-list-item-separator-color: #26233a;
  --theme-list-item-warning-color: #f6c177;
  --theme-list-item-error-color: #eb6f92;
  --theme-right-icon-color: #191724;
  --theme-palette-black: #191724;
  --theme-palette-white: #e0def4;
  --theme-image-preview-background-filter: invert(1) invert(28%) sepia(19%) saturate(770%) hue-rotate(183deg) brightness(64%) contrast(87%);
  --theme-image-preview-border-color: #4D5362;
  --theme-image-preview-frame-border-color: #e0def4;
  --theme-drop-indicator-can-drop-color: #c4a7e7;
  --theme-drop-indicator-cannot-drop-color: #eb6f92;
  --theme-drop-indicator-border-color: #1f1d2e;
  --theme-home-separator-color: #32323B;
  --theme-linear-progress-color-complete: #16CF89; /* Palette/Green/50 */
  --theme-linear-progress-color-incomplete: #FFA929; /* Palette/Yellow/50 */
  --theme-example-difficulty-color-simple: #8BE7C4;
  --theme-example-difficulty-color-advanced: #FFD28F;
  --theme-example-difficulty-color-expert: #FFA38F;
  --theme-tabs-text-color: #7F7F85; /* Palette/Grey/50 */
  --theme-tabs-background-color: #1F1D2A;
  --theme-tabs-indicator-color: #A6A6AB; /* Palette/Grey/40 */
  --theme-tabs-separator-color: #606066; /* Palette/Grey/60 */
  --theme-tabs-selected-text-color: #FAFAFA; /* Palette/Grey/00 */
  --theme-tabs-selected-background-color: #494952; /* Palette/Grey/70 */
  --input-border-bottom-color: #6e6a86;
  --event-sheet-event-tree-background-color: #191724;
  --event-sheet-rst-move-handle-background-color: #ebbcba;
  --event-sheet-rst-move-handle-hover-background-color: #c4a7e7;
  --event-sheet-rst-line-background-color: #6e6a86;
  --event-sheet-rst-row-contents-background-color: #191724;
  --event-sheet-rst-row-contents-color: #e0def4;
  --event-sheet-selectable-background-color: rgba(0, 0, 0, 0.2);
  --event-sheet-selectable-border: 1px #191724 solid;
  --event-sheet-selectable-selected-border: 1px #c4a7e7 solid;
  --event-sheet-conditions-background-color: #1f1d2e;
  --event-sheet-conditions-border-color: #1f1d2e;
  --event-sheet-conditions-border: 1px solid #1f1d2e;
  --event-sheet-conditions-color: #e0def4;
  --event-sheet-conditions-border-right-color: #1f1d2e;
  --event-sheet-conditions-border-bottom-color: #191724;
  --event-sheet-events-highlighted-background-color: #5E5137;
  --event-sheet-events-highlighted-color: #f6c177;
  --event-sheet-actions-background-color: #191724;
  --event-sheet-actions-color: #e0def4;
  --event-sheet-sub-instructions-border-color: #191724;
  --event-sheet-sub-instructions-border: 1px solid #191724;
  --event-sheet-instruction-parameter-base-color: #9ccfd8;
  --event-sheet-instruction-parameter-number-color: #9ccfd8;
  --event-sheet-instruction-parameter-object-color: #ebbcba;
  --event-sheet-instruction-parameter-behavior-color: rgb(119, 119, 119);
  --event-sheet-instruction-parameter-operator-color: #c4a7e7;
  --event-sheet-instruction-parameter-var-color: #f6c177;
  --event-sheet-instruction-parameter-error-color: #eb6f92;
  --event-sheet-instruction-parameter-error-background-color: rgba(243, 0, 12, 0.15);
  --event-sheet-instruction-parameter-warning-color: #f6c177;
  --event-sheet-drop-indicator-can-drop-border-top-color: #6e6a86;
  --event-sheet-drop-indicator-cannot-drop-border-top-color: #eb6f92;
  --event-sheet-link-container-background-color: #191724;
  --event-sheet-link-container-color: #e0def4;
  --event-sheet-link-selectable-link-color: #ebbcba;
  --markdown-blockquote-border-left-color: #6e6a86;
  --markdown-link-color: #e0def4;
  --mosaic-title-color: #ebbcba;
  --mosaic-layout-border-color: #888888;
  --mosaic-layout-background-color: #1f1d2e;
  --mosaic-toolbar-background-color: #26233a;
  --mosaic-toolbar-border-color-hover: #c4a7e7;
  --table-border-color: #1f1d2e;
  --table-text-color: #e0def4;
  --table-text-color-header: #6e6a86;
  --table-row-odd-background-color: #1f1d2e;
  --table-row-even-background-color: #191724;
  --table-row-selected-background-color: #c4a7e7;
  --table-row-selected-color: #191724;
}
